
@font-face {
  font-family: 'Alibaba-Sans-Black';
  src: url('./font/Alibaba-Sans-Black.otf');
}

@font-face {
  font-family: 'Alibaba-PuHuiTi-Medium';
  src: url('./font/Alibaba-PuHuiTi-Medium.otf');
}

body {
  
  min-height: 100vh;
  background: url('../assets/images/bg-web.png') no-repeat;
  background-size: 100% 100%;
  background-position: 0 6.25rem;
}
#swap-button,#join-pool-button {
  box-shadow: inset 0px -2px 0px 0px rgba(0,0,0,0.2);
}

.language-popover {
  padding: 0.94rem 0;
  position: relative;
}

.language-popover::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 3.5rem;
  z-index: 9999;
}

.language-popover::before {
  position: absolute;
  top:-3rem;
  content: '';
  width: 100%;
  height: 3.5rem;
  z-index: 9999;
}

.language-popover p {
  width: 9.38rem;
  text-align: center;
  line-height: 2.5rem;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 0;
  font-family: 'Alibaba-PuHuiTi-Medium';
}

.language-popover p:hover {
  color: #FFC300;
}

@media (max-width: 500px) {
  body  {
    background: url('../assets/images/bg-h5.png') no-repeat;
    background-size: 100% 100%;
    background-position: 0 3rem;
  }
}